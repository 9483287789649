import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import useNavigate
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  validateName,
  validateEmail,
  validateIban,
} from "../../utils/formValidations";
import { addBankAccountDetails } from "../../services/bankAccountDetails";
import InputField from "../components/InputField";
import "./UpdateBankAccountDetails.css";

const initialValues = {
  name: "",
  email: "",
  phone: "",
  iban: "",
  bic: "",
  address: {
    country: "",
    street_address: "",
    postal_code: "",
    recipient_name: "",
    city: "",
    company_name: "",
  },
};

const initialErrors = {
  nameError: null,
  emailError: null,
  ibanError: null,
  addressErrors: {
    countryError: null,
    streetAddressError: null,
    postalCodeError: null,
    cityError: null,
  },
};

export default function AddBankAccountDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState(initialErrors);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();

      navigate(`/station-details/${id}`);
    };

    window.onpopstate = handleBackButton;

    return () => {
      window.onpopstate = null;
    };
  }, [navigate, id]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setValues((prevValues) => {
      if (id.startsWith("address.")) {
        return {
          ...prevValues,
          address: { ...prevValues.address, [id.split(".")[1]]: value },
        };
      }
      return { ...prevValues, [id]: value };
    });
    setErrors((prevErrors) => ({ ...prevErrors, [`${id}Error`]: null }));
  };

  const validateFields = () => {
    const nameValidation = validateName(values.name);
    const emailValidation = validateEmail(values.email);
    const ibanValidation = validateIban(values.iban);
    const addressErrors = {
      countryError: values.address.country ? null : "Country is required.",
      streetAddressError: values.address.street_address
        ? null
        : "Street Address is required.",
      postalCodeError: values.address.postal_code
        ? null
        : "Postal Code is required.",
      cityError: values.address.city ? null : "City is required.",
    };
    setErrors({
      nameError: nameValidation,
      emailError: emailValidation,
      ibanError: ibanValidation,
      addressErrors,
    });
    return (
      !nameValidation &&
      !emailValidation &&
      !ibanValidation &&
      !Object.values(addressErrors).some(Boolean)
    );
  };

  const handleSave = () => {
    if (validateFields()) {
      setLoading(true);
      const payload = {
        account_holder_name: values.name,
        email: values.email,
        phone: values.phone,
        iban: values.iban,
        bic: values.bic,
        address: values.address,
      };
      addBankAccountDetails(id, payload)
        .then(() => alert("Bank account details added successfully"))
        .catch((error) => {
          console.error("Failed to add bank account details:", error);
          alert("Failed to add bank account details");
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className="container">
      {loading ? (
        <div className="container">
          <ProgressSpinner />
          <div>Loading...</div>
        </div>
      ) : (
        <div className="custom-card-width">
          <div className="flex-between">
            <h4>Add Bank account information: </h4>
          </div>
          <div className="flex flex-column md:flex-row gap-3">
            {[
              "name",
              "iban",
              "bic",
              "email",
              "phone",
              "address.country",
              "address.street_address",
              "address.postal_code",
              "address.city",
              "address.recipient_name",
              "address.company_name",
            ].map((field) => (
              <InputField
                key={field}
                id={field}
                label={`${field
                  .replace("address.", "")
                  .replace("_", " ")
                  .toUpperCase()}${
                  [
                    "name",
                    "iban",
                    "email",
                    "address.country",
                    "address.street_address",
                    "address.postal_code",
                    "address.city",
                  ].includes(field)
                    ? "*"
                    : ""
                }`}
                value={
                  field.includes("address.")
                    ? values.address[field.split(".")[1]]
                    : values[field]
                }
                onChange={handleChange}
                error={
                  field.includes("address.")
                    ? errors.addressErrors?.[`${field.split(".")[1]}Error`]
                    : errors[`${field}Error`]
                }
                icon={
                  field.includes("email")
                    ? "pi pi-at"
                    : field.includes("phone")
                    ? "pi pi-phone"
                    : field.includes("iban") || field.includes("bic")
                    ? "pi pi-building-columns"
                    : "pi pi-user"
                }
                placeholder={
                  ![
                    "name",
                    "iban",
                    "email",
                    "address.country",
                    "address.street_address",
                    "address.postal_code",
                    "address.city",
                  ].includes(field)
                    ? "(Optional)"
                    : ""
                }
              />
            ))}
          </div>
          <div className="button-container">
            <Button
              label="Save"
              className="p-button-raised save-button"
              onClick={handleSave}
            />
          </div>
        </div>
      )}
    </div>
  );
}
