import { clearingApi } from "../config/apiConfig";

export const getBankAccountDetails = async (id) => {
  try {
    const response = await clearingApi.get(`payout-details?station_id=${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getBankAccountsList = async () => {
  try {
    const response = await clearingApi.get(`payout-details/all`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const addBankAccountDetails = async (id, payload) => {
  try {
    const response = await clearingApi.post("/payout-details", {
      station_id: id,
      account_holder_name: payload.account_holder_name,
      iban: payload.iban,
      bic: payload.bic || "",
      email: payload.email,
      phone: payload.phone || "",
      address: payload.address || {},
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const updateBankAccountDetails = async (id, payload) => {
  try {
    const response = await clearingApi.put(`/payout-details?station_id=${id}`, {
      station_id: id,
      account_holder_name: payload.account_holder_name,
      iban: payload.iban,
      bic: payload.bic || "",
      email: payload.email,
      phone: payload.phone || "",
      address: payload.address || {},
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error updating bank account details:",
      error.response?.data || error.message
    );
    throw error;
  }
};
