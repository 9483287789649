import React from "react";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputSwitch } from "primereact/inputswitch";
import "./styles.css";

const DetailCard = ({ details, onInputChange, onUpdate, loading }) => {
  const formatTitle = (key) => {
    return key
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <Card className="details-card">
      <div className="details-grid">
        {Object.keys(details)
          .filter((key) => key.toLowerCase() !== "mandantname")
          .map((key) => (
            <div key={key} className="details-item">
              <label htmlFor={key} className="details-label">
                {formatTitle(key)}:
              </label>
              {typeof details[key] === "boolean" ? (
                <InputSwitch
                  id={key}
                  checked={details[key]}
                  onChange={(e) => onInputChange(key, e.value)}
                  className={`details-switch ${
                    details[key] ? "switch-active" : ""
                  }`}
                />
              ) : (
                <InputText
                  id={key}
                  value={details[key]}
                  onChange={(e) => onInputChange(key, e.target.value)}
                  className="details-input"
                  disabled={key.toLowerCase() === "email"}
                />
              )}
            </div>
          ))}
      </div>
      <div className="update-button">
        <Button
          severity="success"
          label="Update"
          icon="pi pi-check"
          onClick={onUpdate}
          disabled={loading}
        />
      </div>
    </Card>
  );
};

export default DetailCard;
