import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CalendarOverlay = ({ selectedDate, onChange }) => (
  <div className="month-picker-overlay">
    <DatePicker
      selected={selectedDate}
      onChange={onChange}
      dateFormat="MM/yyyy"
      showMonthYearPicker
      inline
    />
  </div>
);

export default CalendarOverlay;
