import { clearingApi } from "../config/apiConfig";

export const getClearing = async () => {
  try {
    const response = await clearingApi.get(`backoffice/emps`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllBills = async () => {
  try {
    const response = await clearingApi.post(`bills/all`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getBillsByDate = async (mandateName, date) => {
  try {
    const response = await clearingApi.post(
      `bills?mandant_name=${mandateName}&${date}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getAllNotes = async () => {
  try {
    const response = await clearingApi.post(`credit-notes/all`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getNotesByDate = async (stationId, date) => {
  try {
    const response = await clearingApi.post(
      `credit-notes?station_id=${stationId}&${date}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateClearing = async (id, data) => {
  try {
    data = {
      ...data,
      email: [data.email],
    };
    const response = await clearingApi.put(`backoffice/emps/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const SyncCDR = async () => {
  try {
    const response = await clearingApi.post(`cdrs/sync`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
