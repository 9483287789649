import React from "react";
import { Dropdown } from "primereact/dropdown";

const StationDropdown = ({ value, options, onChange }) => (
  <div className="dropdown-wrapper">
    <Dropdown
      value={value}
      options={options}
      onChange={(e) => onChange(e.value)}
      optionLabel="name"
      placeholder="Select a Station"
      className="station-dropdown"
    />
  </div>
);

export default StationDropdown;
