import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
  getNotesByDate,
  getBillsByDate,
  updateClearing,
} from "../../services/clearing";
import { getStationsApi } from "../../services/stations";
import CalendarOverlay from "./components/CalendarOverlay ";
import StationDropdown from "../../components/StationDropdown ";
import DetailCard from "./components/DetailCard ";
import { showToast, formatDate } from "./utils/formatDate ";
import "./ClearingDetails.css";

const ClearingDetail = () => {
  const location = useLocation();
  const { details: initialDetails } = location.state || {};
  const [details, setDetails] = useState(initialDetails || {});
  const [showBillCalendar, setShowBillCalendar] = useState(false);
  const [showNotesCalendar, setShowNotesCalendar] = useState(false);
  const [showStationDropdown, setShowStationDropdown] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [selectedStation, setSelectedStation] = useState(null);
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    getStationsApi()
      .then(setStations)
      .catch((err) => console.error("Failed to fetch stations:", err));
  }, []);

  const handleInputChange = (key, value) => {
    setDetails((prevDetails) => ({ ...prevDetails, [key]: value }));
  };

  const handleGenerateBills = useCallback(() => {
    setShowBillCalendar(true);
    setShowNotesCalendar(false);
    setShowStationDropdown(false);
  }, [setShowBillCalendar, setShowNotesCalendar, setShowStationDropdown]);

  const handleGenerateNotes = () => {
    setShowStationDropdown(true);
    setShowBillCalendar(false);
    setShowNotesCalendar(false);
  };

  const onBillsMonthChange = async (date) => {
    setSelectedMonth(date);
    setShowBillCalendar(false);
    setLoading(true);
    try {
      const formattedDate = formatDate(date);
      await getBillsByDate(details.mandantName, formattedDate);
      showToast(
        toast,
        "success",
        "Success",
        `Bills for ${date.toLocaleString("default", {
          month: "long",
        })} ${date.getFullYear()} generated successfully!`
      );
    } catch (err) {
      showToast(toast, "error", "Error", "Failed to generate EMP bills.");
    } finally {
      setLoading(false);
    }
  };

  const onStationSelect = (station) => {
    setSelectedStation(station);
    setShowNotesCalendar(true);
    setShowStationDropdown(false);
  };

  const onNotesMonthChange = async (date) => {
    setSelectedMonth(date);
    setShowNotesCalendar(false);
    if (selectedStation) {
      setLoading(true);
      try {
        const formattedDate = formatDate(date);
        await getNotesByDate(selectedStation.id, formattedDate);
        showToast(
          toast,
          "success",
          "Success",
          `Credit notes for ${selectedStation.name} in ${date.toLocaleString(
            "default",
            { month: "long" }
          )} ${date.getFullYear()} generated successfully!`
        );
      } catch (err) {
        showToast(toast, "error", "Error", "Failed to generate credit notes.");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUpdate = async () => {
    try {
      await updateClearing(details.mandantName, details);
      showToast(
        toast,
        "success",
        "Success",
        "Clearing details updated successfully!"
      );
    } catch (err) {
      showToast(toast, "error", "Error", "Failed to update clearing details.");
    }
  };

  return (
    <div className="clearing-details">
      <Toast ref={toast} />
      <h1>Clearing Details</h1>
      <div className="card flex justify-content-center">
        <Button
          label="Generate EMP Bills by month"
          severity="success"
          onClick={handleGenerateBills}
          disabled={loading}
        />
        <Button
          label="Generate Credit Notes by month"
          severity="success"
          onClick={handleGenerateNotes}
          disabled={loading}
        />
      </div>
      {loading && <div className="loading-indicator">Loading...</div>}
      {showStationDropdown && (
        <StationDropdown
          value={selectedStation}
          options={stations}
          onChange={onStationSelect}
        />
      )}
      {showBillCalendar && (
        <CalendarOverlay
          selectedDate={selectedMonth}
          onChange={onBillsMonthChange}
        />
      )}
      {showNotesCalendar && (
        <CalendarOverlay
          selectedDate={selectedMonth}
          onChange={onNotesMonthChange}
        />
      )}
      {details && Object.keys(details).length > 0 ? (
        <DetailCard
          details={details}
          onInputChange={handleInputChange}
          onUpdate={handleUpdate}
          loading={loading}
        />
      ) : (
        <p>No details available.</p>
      )}
    </div>
  );
};

export default ClearingDetail;
