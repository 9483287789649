import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { PrimeReactProvider } from "primereact/api";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

import Home from "./pages/Home";
import LoginButton from "./pages/Login/Login";
import LogoutButton from "./pages/LogOut/LogOut";
import AddOwner from "./pages/StationManagment/AddOwners/AddOwners";
import Stations from "./pages/StationManagment/StationList/StationList";
import StationDetails from "./pages/StationManagment/StationDetails/StationDetails";
import QrCode from "./pages/StationManagment/QrCode/QrCodeManagment";
import UpdateBankAccountDetails from "./pages/PaymentMethod/UpdateBankAccountDetails";
import Transactions from "./pages/StationManagment/Transactions/TransactionList";
import "./App.css";
import MenuBarNavigation from "./navigation/MenuBarNavigation";
import BankAccountsList from "./pages/PaymentMethod/BankAccountsList";
import AddBankAccountDetails from "./pages/PaymentMethod/AddBankAccountDetails";
import ClearingList from "./pages/Clearing/ClearingList";
import { checkAdminPermissions } from "./utils/checkAdminPermission";
import ClearingDetail from "./pages/Clearing/ClearingDetail";

function App() {
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout } =
    useAuth0();
  const [isAuthenticatedState, setIsAuthenticatedState] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    const verifyAdmin = async () => {
      if (isAuthenticated) {
        const hasAdminAccess = await checkAdminPermissions(
          getAccessTokenSilently
        );
        if (!hasAdminAccess) {
          toast.current.show({
            severity: "error",
            summary: "Access Denied",
            detail: "You do not have admin privileges.",
            life: 3000,
          });

          setTimeout(() => {
            logout({ returnTo: window.location.origin });
            setIsAuthenticatedState(false);
          }, 2000);
        } else {
          setIsAuthenticatedState(true);
        }
      } else {
        setIsAuthenticatedState(false);
      }
    };

    verifyAdmin();
  }, [isAuthenticated, getAccessTokenSilently, logout]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <ProgressSpinner />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <PrimeReactProvider>
      <Router>
        <Toast ref={toast} />
        <div className="app-container">
          {isAuthenticatedState && <MenuBarNavigation />}
          <div className="main-content">
            <Routes>
              {isAuthenticatedState ? (
                <>
                  <Route path="/" element={<Home />} />
                  <Route path="/logout" element={<LogoutButton />} />
                  <Route path="/add-owner/:id" element={<AddOwner />} />
                  <Route path="/station-list" element={<Stations />} />
                  <Route path="/qrcode-managment/:id" element={<QrCode />} />
                  <Route
                    path="/update-bank-account-details/:id"
                    element={<UpdateBankAccountDetails />}
                  />
                  <Route
                    path="/add-bank-account-details/:id"
                    element={<AddBankAccountDetails />}
                  />
                  <Route
                    path="/station-details/:id"
                    element={<StationDetails />}
                  />
                  <Route path="/clearing-list" element={<ClearingList />} />
                  <Route
                    path="/clearing-details"
                    element={<ClearingDetail />}
                  />
                  <Route
                    path="/bank-accounts-list/"
                    element={<BankAccountsList />}
                  />
                  <Route path="/transactions/:id" element={<Transactions />} />
                </>
              ) : (
                <Route path="/*" element={<LoginButton />} />
              )}
            </Routes>
          </div>
        </div>
      </Router>
    </PrimeReactProvider>
  );
}

export default App;
