import React, { useState, useEffect } from "react";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { getBankAccountsList } from "../../services/bankAccountDetails";
import { getStationsApi } from "../../services/stations";
import "./BankAccountsList.css";

export default function BankAccountsList() {
  const [bankAccountList, setBankAccountList] = useState([]);
  const [filters] = useState({
    iban: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });
  const [loading, setLoading] = useState(true);
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getStationsApi()
      .then(setStations)
      .catch((err) => console.error("Failed to fetch stations:", err));
  }, []);

  useEffect(() => {
    const storedStation = sessionStorage.getItem("selectedStation");
    if (storedStation) {
      setSelectedStation(JSON.parse(storedStation));
    }
  }, []);

  useEffect(() => {
    getBankAccountsList()
      .then((data) => {
        setBankAccountList(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch bank accounts:", error);
        setLoading(false);
      });
  }, []);

  const onStationSelect = (station) => {
    setSelectedStation(station);
    sessionStorage.setItem("selectedStation", JSON.stringify(station));

    if (showDropdown) {
      navigate(`/add-bank-account-details/${station.id}`);
    }
  };

  const handleCreateNewPayout = () => {
    if (!selectedStation) {
      setShowDropdown(true);
    } else {
      navigate(`/add-bank-account-details/${selectedStation.id}`);
    }
  };

  const handleUpdate = (stationId) => {
    navigate(`/update-bank-account-details/${stationId}`);
  };

  return (
    <div className="bank-accounts-list">
      <div className="station-dropdown-wrapper">
        <Button
          label="Create New Payout"
          icon="pi pi-plus"
          onClick={handleCreateNewPayout}
          className="p-button-success"
        />
      </div>

      {showDropdown && (
        <div className="station-dropdown-container">
          <div className="bank-accounts-item">
            <span className="bank-accounts-label">Select a Station</span>
            <Dropdown
              value={selectedStation}
              options={stations}
              onChange={(e) => onStationSelect(e.value)}
              optionLabel="name"
              placeholder="Select a Station"
              className="bank-accounts-input"
            />
          </div>
        </div>
      )}

      <div className="bank-accounts-card">
        <DataTable
          value={bankAccountList}
          paginator
          rows={10}
          dataKey="iban"
          filters={filters}
          filterDisplay="row"
          loading={loading}
          emptyMessage="No bank accounts found."
          style={{ width: "100%" }}
        >
          <Column
            field="station_id"
            header="Station ID"
            style={{ flexGrow: 1 }}
          />

          <Column
            field="iban"
            header="IBAN"
            filter
            filterPlaceholder="Search by IBAN"
            style={{ flexGrow: 1 }}
            filterMatchMode="startsWith"
          />

          <Column
            field="email"
            header="Email"
            filter
            filterPlaceholder="Search by Email"
            style={{ flexGrow: 1 }}
            filterMatchMode="startsWith"
          />

          <Column
            header="Action"
            body={(rowData) => (
              <button
                onClick={() => handleUpdate(rowData.station_id)}
                className="p-link"
              >
                Update
              </button>
            )}
            style={{ flexGrow: 1 }}
          />
        </DataTable>
      </div>
    </div>
  );
}
