import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  validateName,
  validateEmail,
  validateIban,
} from "../../utils/formValidations";
import {
  getBankAccountDetails,
  updateBankAccountDetails,
} from "../../services/bankAccountDetails";
import InputField from "../components/InputField";

import "./UpdateBankAccountDetails.css";

export default function StationDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    iban: "",
    bic: "",
    address: {
      country: "",
      street_address: "",
      postal_code: "",
      recipient_name: "",
      city: "",
      company_name: "",
    },
  });

  const [errors, setErrors] = useState({
    nameError: null,
    emailError: null,
    ibanError: null,
    addressErrors: {
      countryError: null,
      streetAddressError: null,
      postalCodeError: null,
      cityError: null,
    },
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBankAccountDetails = async () => {
      try {
        const bankAccountDetails = await getBankAccountDetails(id);

        // Check if all fields are empty
        const isEmpty =
          !bankAccountDetails ||
          !bankAccountDetails.account_holder_name ||
          !bankAccountDetails.iban ||
          !bankAccountDetails.email ||
          !bankAccountDetails.address?.country ||
          !bankAccountDetails.address?.street_address ||
          !bankAccountDetails.address?.postal_code ||
          !bankAccountDetails.address?.city;

        if (isEmpty || !bankAccountDetails) {
          navigate(`/add-bank-account-details/${id}`);
          return;
        }

        setValues({
          name: bankAccountDetails.account_holder_name,
          email: bankAccountDetails.email,
          phone: bankAccountDetails.phone || "",
          iban: bankAccountDetails.iban,
          bic: bankAccountDetails.bic || "",
          address: {
            country: bankAccountDetails.address?.country || "",
            street_address: bankAccountDetails.address?.street_address || "",
            postal_code: bankAccountDetails.address?.postal_code || "",
            recipient_name: bankAccountDetails.address?.recipient_name || "",
            city: bankAccountDetails.address?.city || "",
            company_name: bankAccountDetails.address?.company_name || "",
          },
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBankAccountDetails();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id.startsWith("address.")) {
      const addressField = id.split(".")[1];
      setValues((prevValues) => ({
        ...prevValues,
        address: {
          ...prevValues.address,
          [addressField]: value,
        },
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        addressErrors: {
          ...prevErrors.addressErrors,
          [`${addressField}Error`]: null,
        },
      }));
    } else {
      setValues((prevValues) => ({
        ...prevValues,
        [id]: value,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [`${id}Error`]: null,
      }));
    }
  };

  const handleSave = () => {
    const nameValidation = validateName(values.name);
    const emailValidation = validateEmail(values.email);
    const ibanValidation = validateIban(values.iban);

    const addressErrors = {
      countryError: values.address.country ? null : "Country is required.",
      streetAddressError: values.address.street_address
        ? null
        : "Street Address is required.",
      postalCodeError: values.address.postal_code
        ? null
        : "Postal Code is required.",
      cityError: values.address.city ? null : "City is required.",
    };

    setErrors({
      nameError: nameValidation,
      emailError: emailValidation,
      ibanError: ibanValidation,
      addressErrors,
    });

    const hasAddressErrors = Object.values(addressErrors).some(
      (error) => error !== null
    );

    if (
      !nameValidation &&
      !emailValidation &&
      !ibanValidation &&
      !hasAddressErrors
    ) {
      const payload = {
        account_holder_name: values.name,
        iban: values.iban,
        bic: values.bic,
        email: values.email,
        phone: values.phone,
        address: values.address,
      };

      updateBankAccountDetails(id, payload)
        .then(() => {
          alert("Bank account details updated successfully");
        })
        .catch((error) => {
          console.error("Update failed:", error);
          alert("Failed to update bank account details");
        });
    }
  };

  return (
    <div className="container">
      {loading ? (
        <div className="container">
          <ProgressSpinner />
          <div>Loading...</div>
        </div>
      ) : (
        <div className="custom-card-width">
          <div className="flex-between">
            <h4>Update Bank account information: </h4>
          </div>
          <div className="flex flex-column md:flex-row gap-3">
            <InputField
              id="name"
              label="Full Name*"
              value={values.name}
              onChange={handleChange}
              error={errors.nameError}
              icon="pi pi-user"
            />
            <InputField
              id="iban"
              label="IBAN*"
              value={values.iban}
              onChange={handleChange}
              error={errors.ibanError}
              icon="pi pi-building-columns"
            />
            <InputField
              id="bic"
              label="BIC"
              value={values.bic}
              onChange={handleChange}
              error={null}
              placeholder="(Optional)"
              icon="pi pi-building-columns"
            />
            <InputField
              id="email"
              label="Email*"
              value={values.email}
              onChange={handleChange}
              error={errors.emailError}
              icon="pi pi-at"
            />
            <InputField
              id="phone"
              label="Phone"
              value={values.phone}
              onChange={handleChange}
              error={null}
              placeholder="(Optional)"
              icon="pi pi-phone"
            />
            <InputField
              id="address.country"
              label="Country*"
              value={values.address.country}
              onChange={handleChange}
              error={errors.addressErrors.countryError}
              icon="pi pi-globe"
            />
            <InputField
              id="address.street_address"
              label="Street Address*"
              value={values.address.street_address}
              onChange={handleChange}
              error={errors.addressErrors.streetAddressError}
              icon="pi pi-map"
            />
            <InputField
              id="address.postal_code"
              label="Postal Code*"
              value={values.address.postal_code}
              onChange={handleChange}
              error={errors.addressErrors.postalCodeError}
              icon="pi pi-envelope"
            />
            <InputField
              id="address.recipient_name"
              label="Recipient Name"
              value={values.address.recipient_name}
              onChange={handleChange}
              error={null}
              placeholder="(Optional)"
              icon="pi pi-user"
            />
            <InputField
              id="address.city"
              label="City*"
              value={values.address.city}
              onChange={handleChange}
              error={errors.addressErrors.cityError}
              icon="pi pi-map-marker"
            />
            <InputField
              id="address.company_name"
              label="Company Name"
              value={values.address.company_name}
              onChange={handleChange}
              error={null}
              placeholder="(Optional)"
              icon="pi pi-briefcase"
            />
          </div>
          <div className="button-container">
            <Button
              label="Save"
              className="p-button-raised save-button"
              onClick={handleSave}
            />
          </div>
        </div>
      )}
    </div>
  );
}
